import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import ContentBanner from '../../components/Common/ContentBanner'
import Footer from "../../components/App/Footer"
import ShareCampaign from '../../components/Examples/ShareCampaign'
import Helmet from 'react-helmet';
import ProjectStartArea from '../../components/Common/StartProject';
import SEO from '../../components/App/SEO';
import exampleMetaImage from '../../assets/images/examples/preAddPreSave/example-pre-add-pre-save.png';
// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="シェア・友達紹介キャンペーン"
		  desc="リタッチではキャンーンのシェアを応募方法とすることができます。シェアするだけでは応募完了とはならず、シェアした友達が応募することで応募完了となります。"
		  banner={exampleMetaImage}
		  pathname={'/examples/share'}
		  type={'Article'}
		  shortName="シェア・友達紹介キャンペーン"
		  createdAt="2021-05-01"
		/>
	  <Navbar />
	  <ContentBanner
		  pageTitle="シェア・友達紹介"
		homePageText="Home"
		homePageUrl="/"
		activePageText="シェア・友達紹介"
		subDirectories={[{
		  url: '/examples',
		  name: '事例'
		}]}
	  />
	  <ShareCampaign />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples