import React from 'react';
import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from 'react-accessible-accordion';
import WrapperStyle from './examples.style';
import OptimizeExample from './OptimizeExample';
import PopularExamples from './PopularExample';
import ScriptTag from 'react-script-tag';
import {StaticImage} from 'gatsby-plugin-image';

const OurSolutions = () => {
    return (
      <WrapperStyle>
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h1>ショップ・実店舗キャンペーン
                    </h1>
                    <p>
                        実店舗を経営されている場合でも、宣伝、リード獲得、オンライン認知拡大、来店促進、顧客獲得を促進するために使用することができます。
                    </p>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12">
                        <a data-retach-widget="embed" href="https://retach.biz/dashboard/promotions/6bd9d4da584143ecd1" rel="nofollow">リタッチ</a>
                        <ScriptTag  type="text/javascript" src="https://retach.biz/scripts/promotionCall.js"/>
                    </div>
                </div>
            </div>
        </section>
          <section className="ptb-100 top-question" >
              <div className="container">
                  <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Question
                    </span>
                      <h2 id="question">よくあるご質問</h2>
                  </div>
                  <div className="faq-accordion">
                      <Accordion allowMultipleExpanded={true}>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      店舗ごとにLINE公式アカウントをもっていますが、１つのキャンペーンで複数の公式アカウントの友だち追加を応募条件にできますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      いいえ、できません。１つのキャンペーンでは１つの公式アカウントの友だち追加のみ応募条件とすることが可能です。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      来店したお客様にクーポンを提供することは可能ですか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。可能です。リタッチのランディングページのQRコードをショップに展示してください。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      賞品にLINEクーポンを提供できますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。可能です。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      複数のブランドがありますが、リタッチでは１つのアカウントでキャンペーンを管理できますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。可能です。リタッチではサイトごとにキャンペーンを管理できます。サイトがブランドごとに分かれている場合は、サイトごとにキャンペーンを作成してください。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                                  応募者の住所や電話番号を取得できますか？
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <p>
                                  はい。応募者に入力して頂く項目は自由に変更できます。応募者情報の設定についてはこちらをご覧ください。
                              </p>
                          </AccordionItemPanel>
                      </AccordionItem>
                      </Accordion>
                  </div>
              </div>
          </section>
          <OptimizeExample types={['lineApp','coupon','receipt']}  />
          <PopularExamples exclude={['receipt','lineApp','coupon']} />
      </WrapperStyle>
    )
};

export default OurSolutions